<template>
<div id="mapiallprojeclist">
  <section class="box">
    <b-field grouped position="is-centered">
      <b-input placeholder="创建者" v-model="creator"/>
      <b-input placeholder="项目名称" v-model="ProjectName"/>
      <div class="buttons">
        <button class="button is-primary" @click="filterChange">筛选</button>
        <button class="button is-warning" @click="resetData">重置</button>
      </div>
    </b-field>
  </section>
  <b-table id="mapiallprojects"
    :data="curPageProjects"
    paginated backend-pagination
    :total="total"
    :current-page.sync="currentPage"
    :range-before="rangeBefore"
    :range-after="rangeAfter"
    :order="order"
    :per-page="perPage"
    @page-change="onPageChange">
    <b-table-column #default="props" field="status" label="状态" width="80" centered>
      <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
    </b-table-column>
    <b-table-column #default="props" field="admin" label="创建者" width="120" centered>
      {{props.row.nickname}}
    </b-table-column>
    <b-table-column #default="props" field="pid" label="项目ID" width="80" centered>
      {{ props.row.pid }}
    </b-table-column>
    <b-table-column #default="props" field="pname" label="项目名称" width="300" centered>
      {{ props.row.pname }}
    </b-table-column>
    <b-table-column #default="props" field="createtime" label="创建时间" centered>
      {{ formatDateTime(props.row.createtime) }}
    </b-table-column>
    <b-table-column #default="props" field="updatetime" label="更新时间" centered>
      {{ formatDateTime(props.row.updatetime) }}
    </b-table-column>
    <b-table-column #default="props">
      <div class="buttons">
        <button class="button is-info" @click="OnSelectProject(props.row)">选择</button>
      </div>
    </b-table-column>
    <template #bottom-left>
      <div class="buttons">
        <button class="button is-primary" @click="onAddProjectClick">新建项目</button>
      </div>
    </template>
  </b-table>
</div>
</template>

<script>

import config from '@/core/config'

export default {
  name: 'mapiallprojects',
  components: {},
  data () {
    return {
      allProjects: [],
      displayProjects: [],
      curPageProjects: [],
      perPage: config.PER_PAGE,
      rangeBefore: 3,
      rangeAfter: 3,
      order: 'is-centered',
      currentPage: 1,
      total: 0,
      creator: '',
      ProjectName: ''
    }
  },
  created () {
    console.log('create mapi all p')
    this.getRecords()
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
      this.curPageProjects = this.displayProjects.slice((page - 1) * this.perPage, page * this.perPage)
    },
    async getRecords () {
      try {
        const rdata = await this.mjp.get2({
          url: '/mapi/project/get/all/',
          mjpType: 'admin'
        })
        this.allProjects = rdata.my.concat(rdata.share)// 所有项目
        this.resetData()// 重置显示列表并返回第一页
      } catch (err) {
        console.error(err)
      }
    },
    OnSelectProject (project) {
      this.$emit('select-project', project, this.allProjects)
    },
    onAddProjectClick () {
      this.$emit('add-project')
    },
    filterChange () {
      let filterProjects = this.allProjects.concat()
      if (this.creator !== '') {
        filterProjects = filterProjects.filter(obj => {
          return obj.nickname.toLowerCase().includes(this.creator.toLowerCase().trim())
        })
      }
      if (this.ProjectName !== '') {
        filterProjects = filterProjects.filter(obj => {
          return obj.pname.toLowerCase().includes(this.ProjectName.toLowerCase().trim())
        })
      }
      this.displayProjects = filterProjects
      this.total = this.displayProjects.length
      this.onPageChange(1)// 返回第一页
    },
    resetData () {
      this.creator = ''
      this.ProjectName = ''
      this.displayProjects = this.allProjects.concat()
      this.total = this.displayProjects.length
      this.onPageChange(1)// 返回第一页
    }
  }
}
</script>
