<template>
<div id="mapiporjecteditor">
  <section class="box">
    <b-field grouped position="is-centered">
      <b-field v-if="behavior === 'ps'" label="项目ID">
      <b-input v-model.trim="pid" disabled></b-input>
      </b-field>
      <b-field label="项目名称">
        <b-input name="pname" v-model.trim="pname" :disabled="behavior === 'ps' && !isMineProject"/>
      </b-field>
      <b-field label="项目类型">
        <b-select v-model="ptype" :disabled="behavior === 'ps' && !isMineProject">
          <option value="401">私有</option>
          <option value="402">共享</option>
        </b-select>
      </b-field>
      <b-field label="项目状态">
        <b-select v-model="status" :disabled="behavior === 'ps' && !isMineProject">
          <option value="1">正常</option>
          <option value="5">禁用</option>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped position="is-centered" v-if="behavior === 'pa'">
      <button class="button is-danger" :disabled="!canSave" @click="onSave">保存</button>
    </b-field>
    <b-field grouped position="is-centered" v-if="behavior === 'ps'">
      <button class="button is-danger" v-if="isMineProject" :disabled="!canSave || !isEdit" @click="onSave">确认修改</button>
    </b-field>
  </section>
  <b-field label="目标项目">
    <b-select v-model="selectProject">
      <option v-for="item in allProjects" :value="item" :key="item">{{item.pname}}</option>
    </b-select>
  </b-field>
  <b-table
    v-if="behavior === 'ps'"
    :data="showTpls"
    paginated backend-pagination
    :total="total"
    :current-page.sync="currentPage"
    :order="order"
    :per-page="perPage"
    @page-change="onPageChange"
    detailed detail-key="mid">
    <b-table-column #default="props" field="status" label="状态" width="80" centered>
      <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
    </b-table-column>
    <b-table-column #default="props" field="admin" label="创建者" width="120" centered>
      {{props.row.nickname}}
    </b-table-column>
    <b-table-column #default="props" field="mid" label="模板ID" width="80" centered>
      {{ props.row.mid }}
    </b-table-column>
    <b-table-column #default="props" field="mname" label="名称" width="300" centered>
      {{ props.row.mname }}
    </b-table-column>
    <b-table-column #default="props" field="createtime" label="创建时间" centered>
      {{ formatDateTime(props.row.createtime) }}
    </b-table-column>
    <b-table-column #default="props" field="updatetime" label="更新时间" centered>
      {{ formatDateTime(props.row.updatetime) }}
    </b-table-column>
    <b-table-column #default="props">
      <div class="buttons">
        <button class="button is-warning" @click="onSelectClick(props.row)">选择</button>
        <button class="button is-info" @click="onHistoryClick(props.row)">历史</button>
        <b-button lable="复制" type="is-dark" @click="onCopyClick(props.row)">复制到目标项目</b-button>
      </div>
    </b-table-column>
    <template bottom-left>
      <div class="buttons">
        <button class="button is-primary" @click="onAddTplClick">新建模板</button>
      </div>
    </template>
    <template #detail="{row}">
      <pre style="overflow-x:scroll;white-space:pre-wrap;">{{ row.note }}</pre>
    </template>
  </b-table>
</div>
</template>

<script>

import cache from '@/core/cache'
import config from '@/core/config'

export default {
  name: 'mapiporjecteditor',
  components: {},
  data () {
    return {
      pname: null,
      ptype: 401,
      pid: null,
      padminuid: null,
      status: 1,
      // pa 代表增加，ps 代表选择
      behavior: null,
      project: null,
      tpls: [],
      showTpls: [],
      total: 0,
      currentPage: 1,
      order: 'is-centered',
      perPage: config.PER_PAGE,
      allProjects: [],
      selectProject: null
    }
  },
  computed: {
    canSave () {
      if (this.behavior === 'pa') {
        return Boolean(this.pname)
      } else if (this.behavior === 'ps') {
        return Boolean(this.pname && this.pid)
      }
      return false
    },
    isMineProject () {
      if (this.behavior === 'ps') {
        return cache.isMe(this.padminuid)
      }
      return false
    },
    isEdit () {
      if (this.behavior === 'ps') {
        if ((this.pname !== this.project.pname) || (String(this.status) !== String(this.project.status)) || (String(this.ptype) !== String(this.project.ptype))) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    clear (clearAll) {
      this.pid = null
      this.pname = null
      this.ptype = 401
      this.status = 1
      this.adminuid = null
      if (clearAll) {
        this.project = null
        this.behavior = null
        this.tpls = []
        this.total = 0
      }
    },
    onPageChange (page) {
      this.currentPage = page
      this.showTpls = this.tpls.slice((page - 1) * this.perPage, page * this.perPage)
    },
    setProject (behavior, project) {
      this.behavior = behavior
      this.project = project
      if (this.project) {
        this.pid = this.project.pid
        this.pname = this.project.pname
        this.ptype = this.project.ptype
        this.status = this.project.status
        this.padminuid = this.project.adminuid
        this.getTpls(this.pid)
      } else {
        this.clear()
      }
    },
    setAllProject (allProjects) {
      this.allProjects = allProjects
    },
    async getTpls (pid) {
      try {
        const rdata = await this.mjp.get2({
          url: '/mapi/project/get/',
          query: { pid },
          mjpType: 'admin'
        })
        this.tpls = rdata.tpl
        this.total = this.tpls.length
        this.onPageChange(1)
      } catch (err) {
        console.error(err)
      }
    },
    async onSave () {
      try {
        const data = {
          pname: this.pname,
          ptype: this.ptype
        }
        let url = '/mapi/project/create/'
        if (this.behavior === 'ps') {
          data.pid = this.pid
          data.status = this.status
          url = '/mapi/project/edit/'
        }
        await this.mjp.post2({
          url,
          data,
          mjpType: 'admin'
        })
        // 清除所有本地变量
        this.clear(true)
        this.$emit('project-saved')
      } catch (err) {
        console.error(err)
      }
    },
    onAddTplClick () {
      console.log('onAddTplClick')
      this.$emit('add-tpl', this.project)
    },
    onCallClick (tpl) {
      // this.$emit('call-tpl', tpl)
      console.log('onCallClick')
    },
    onHistoryClick (tpl) {
      this.$emit('tpl-history', tpl.mid, 'ph')
      console.log('onHistoryClick')
    },
    onSelectClick (tpl) {
      console.log('select-tpl')
      this.$emit('select-tpl', tpl)
    },
    onCopyClick (tpl) {
      this.$buefy.dialog.confirm(
        {
          title: '确认复制该模板到以下项目内？',
          message: this.selectProject.pname,
          cancelText: '取消',
          confirmText: '确认',
          type: 'is-success',
          onConfirm: () => {
            try {
              const data = {
                pid: this.selectProject.pid,
                mid: tpl.mid
              }
              this.mjp.post2({
                url: '/mapi/tpl/copy/',
                data,
                mjpType: 'admin'
              })
            } catch (err) {
              console.error(err)
            }
          }
        }
      )
    }
  }
}
</script>
