<template>
  <div id="maptpleditor">
    <section>
      <b-field grouped horizontal label="编辑模板" v-show="isMineTpl || canEditFlag || isSuperAdmin">
        <!-- 仅所有者或者有编辑权限者能使用编辑按钮 -->
        <b-switch v-model="showEdit"></b-switch>
      </b-field>
      <b-field class="box" v-show="(showEdit && (isMineTpl || isSuperAdmin)) || behavior === 'ta'">
        <!-- 设置分享权限功能只能是所有者 -->
        <b-field label="API分享" calss="box">
          <section>
            <b-switch v-model="selectAll" @input="onSelectAll">选择全部</b-switch>&nbsp;
            <div>
              <b-checkbox v-model="selectShareTo" v-for="o in users"
                :native-value="o.adminuid" :key="o.adminuid">
                {{ o.nickname }}
              </b-checkbox>
            </div>
          </section>
        </b-field>
      </b-field>
      <b-field class="box" v-show="(showEdit && isMineTpl || isSuperAdmin) || behavior === 'ta'">
        <!-- 设置编辑权限功能只能是所有者 -->
        <b-field label="编辑权限" calss="box">
          <section>
            <div>
              <b-checkbox v-model="selectWhoCanEdit" v-for="o in editorpool"
                :native-value="o.adminuid" :key="o.adminuid">
                {{ o.nickname }}，
              </b-checkbox>
            </div>
          </section>
        </b-field>
      </b-field>
      <b-field class="box" v-show="showEdit || behavior === 'ta'">
        <!-- 以下为编辑模板界面 -->
        <section>
          <!---- 通用设置 ---->
          <b-field grouped expanded>
            <b-select v-model="status">
              <option value="1">正常</option>
              <option value="5">禁用</option>
            </b-select>
            <b-field label="API名称" :label-position="labelPosition" expanded>
              <b-input name="mname" v-model.trim="mname" placeholder="填写API名称，建议使用高识别度的名称，例如“添加金币”等"/>
            </b-field>
          </b-field>
          <b-field grouped expanded>
            <b-select v-model="method">
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="DELETE">DELETE</option>
              <option value="PUT">PUT</option>
            </b-select>
            <b-field label="URL" :label-position="labelPosition" expanded>
              <b-input name="url" v-model.trim="url" placeholder="提供完整的url，如：https://www.baidu.com"/>
            </b-field>
          </b-field>
          <b-field label="API说明" :label-position="labelPosition" expanded>
            <b-input expanded type="textarea" v-model.trim="note" rows="10" maxlength="200"></b-input>
          </b-field>
          <!------------------>

          <!---- 编辑消息头与消息体 ---->
          <b-field>
            <b-switch v-model="editProfessional"> 编辑专业版模板 </b-switch>
            <button class="button is-small is-primary"
                @click="isSchemaExpOpen = !isSchemaExpOpen">Schema示例</button>
          </b-field>
          <json-editor-schema v-show="isSchemaExpOpen"/>
          <b-tabs v-model="editTab">
            <!---- 公用header ---->
            <b-tab-item label="Header">
              <b-field grouped expanded>
                <b-field class="box" expanded>
                  <JsonEditor
                      :options="{
                          confirmText: '确认',
                          cancelText: '取消',
                      }"
                      :objData="jsonDataHeader"
                      v-model="jsonDataHeader" >
                  </JsonEditor>
                </b-field>
                <b-field label="json" :label-position="labelPosition" expanded>
                  <div class="w-2">
                    <div class="code-pre">
                      <div slot="content">
                        <pre>
                          <code class="json" id="header_code"></code>
                        </pre>
                      </div>
                    </div>
                  </div>
                </b-field>
              </b-field>
            </b-tab-item>

            <!---- 普通版query ---->
            <b-tab-item label="Query" :visible="!editProfessional">
              <b-field grouped expanded>
                <!-- 模板界面区 -->
                <b-field class="box" expanded>
                  <div id="query_ori_holder"></div>
                </b-field>

                <!-- 模板生成默认Json -->
                <b-field label="默认Json" :label-position="labelPosition" expanded>
                  <div class="w-2">
                    <div class="code-pre">
                      <div slot="content">
                        <pre>
                          <code class="json" id="query_ori_code"></code>
                        </pre>
                      </div>
                    </div>
                  </div>
                </b-field>
              </b-field>

              <!-- schema编辑区 -->
              <b-field label="Schema" :label-position="labelPosition">
                <section>
                 <b-input type="textarea" v-model.trim="schemaJsonQueryOri" rows="10" expanded></b-input>
                 <b-button type="is-primary" expanded @click="onUpdateQuerySchema">更新</b-button>
                </section>
              </b-field>
            </b-tab-item>

            <!---- 普通版body ---->
            <b-tab-item label="Body" :visible="!editProfessional">
              <b-field grouped expanded>
                <!-- 模板界面区 -->
                <b-field class="box" expanded>
                  <div id="body_ori_holder"></div>
                </b-field>

                <!-- 模板生成默认Json -->
                <b-field label="默认Json" :label-position="labelPosition" expanded>
                  <div class="w-2">
                    <div class="code-pre">
                      <div slot="content">
                        <pre>
                          <code class="json" id="body_ori_code"></code>
                        </pre>
                      </div>
                    </div>
                  </div>
                </b-field>
              </b-field>

              <!-- schema编辑区 -->
              <b-field label="Schema" :label-position="labelPosition">
                <section>
                 <b-input type="textarea" v-model.trim="schemaJsonBodyOri" rows="10" expanded></b-input>
                 <b-button type="is-primary" expanded @click="onUpdateBodySchema">更新</b-button>
                </section>
              </b-field>
            </b-tab-item>

            <!---- 专业版Query ---->
            <b-tab-item label="Query" :visible="editProfessional">
              <b-field grouped expanded>
                <b-field class="box" expanded>
                  <JsonEditor
                      :options="{
                          confirmText: '确认',
                          cancelText: '取消',
                      }"
                      :objData="jsonDataQuery"
                      v-model="jsonDataQuery" >
                  </JsonEditor>
                </b-field>
                <b-field label="json" :label-position="labelPosition" expanded>
                  <div class="w-2">
                    <div class="code-pre">
                      <div slot="content">
                        <pre>
                          <code class="json" id="query_code"></code>
                        </pre>
                      </div>
                    </div>
                  </div>
                </b-field>
              </b-field>
            </b-tab-item>

            <!---- 专业版Body ---->
            <b-tab-item label="Body" :visible="editProfessional">
              <b-field grouped expanded>
                <b-field class="box" expanded>
                  <JsonEditor
                      :options="{
                          confirmText: '确认',
                          cancelText: '取消',
                      }"
                      :objData="jsonDataBody"
                      v-model="jsonDataBody" >
                  </JsonEditor>
                </b-field>
                <b-field label="json" :label-position="labelPosition" expanded>
                  <div class="w-2">
                    <div class="code-pre">
                      <div slot="content">
                        <pre>
                          <code class="json" id="body_code"></code>
                        </pre>
                      </div>
                    </div>
                  </div>
                </b-field>
              </b-field>
            </b-tab-item>
          </b-tabs>
          <b-field grouped>
            <b-select placeholder="选择使用mjst" v-model="mjstType">
              <option value="0">不使用mjst</option>
              <option value="1">自己的mjst</option>
              <option value="2">可信赖的mjst</option>
            </b-select>
            <b-field>
              <button class="button is-danger" :disabled="!canSave" @click="onSave">保存</button>
            </b-field>
          </b-field>
        </section>
      </b-field>
      <b-field v-show="(behavior === 'ts') && !showEdit">
        <section>
          <b-field horizontal label="API名称">
            <b-input expanded :disabled=true v-model.trim="mname"></b-input>
          </b-field>
          <b-field horizontal label="API说明">
            <b-input expanded type="textarea" v-model.trim="note" rows="5" :disabled=true></b-input>
          </b-field>
          <b-field horizontal label="使用专业版模板">
            <b-switch v-model="useProfessional"></b-switch>
          </b-field>
          <b-field horizontal label="">
            <b-tabs v-model="useTab" position="is-centered">
              <!---- 普通版query ---->
              <b-tab-item label="Query" :visible="!useProfessional && showQueryOp">
                <b-field grouped expanded>
                  <!-- 界面区 -->
                  <b-field class="box" expanded>
                    <section>
                      <div id="query_ori_op"></div>
                      <b-button type="is-primary" expanded @click="onUpdateQueryJson">更新</b-button>
                    </section>
                  </b-field>

                  <!-- 生成Json -->
                  <b-field label="生成Json" :label-position="labelPosition" expanded>
                    <div class="w-2">
                      <div class="code-pre">
                        <div slot="content">
                          <pre>
                            <code class="json" id="query_ori_op_code"></code>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </b-field>
                </b-field>
              </b-tab-item>

              <!---- 普通版body ---->
              <b-tab-item label="Body" :visible="!useProfessional && showBodyOp">
                <b-field grouped>
                  <!-- 界面区 -->
                  <b-field class="box" width="180">
                    <section>
                      <div id="body_ori_op"></div>
                      <b-button type="is-primary" expanded @click="onUpdateBodyJson">更新</b-button>
                    </section>
                  </b-field>

                  <!-- 生成Json -->
                  <b-field label="生成Json" :label-position="labelPosition">
                    <div class="w-2">
                      <div class="code-pre">
                        <div slot="content">
                          <pre>
                            <code class="json" id="body_ori_op_code"></code>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </b-field>
                </b-field>
              </b-tab-item>

              <!---- 专业版Query ---->
              <b-tab-item label="Query" :visible="useProfessional">
                <b-field grouped expanded>
                  <b-field class="box" width="180">
                    <JsonEditor
                        :options="{
                            confirmText: '确认',
                            cancelText: '取消',
                        }"
                        :objData="jsonDataQueryRequests"
                        v-model="jsonDataQueryRequests">
                    </JsonEditor>
                  </b-field>
                  <b-field label="json" :label-position="labelPosition">
                    <div class="w-2">
                      <div class="code-pre">
                        <div slot="content">
                          <pre>
                            <code class="json" id="query_requests"></code>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </b-field>
                </b-field>
              </b-tab-item>

              <!---- 专业版Body ---->
              <b-tab-item label="Body" :visible="useProfessional">
                <b-field grouped expanded>
                  <b-field class="box" expanded>
                    <JsonEditor
                        :options="{
                            confirmText: '确认',
                            cancelText: '取消',
                        }"
                        :objData="jsonDataBodyRequests"
                        v-model="jsonDataBodyRequests" >
                    </JsonEditor>
                  </b-field>
                  <b-field label="json" :label-position="labelPosition" expanded>
                    <div class="w-2">
                      <div class="code-pre">
                        <div slot="content">
                          <pre>
                            <code class="json" id="body_requests"></code>
                          </pre>
                        </div>
                      </div>
                    </div>
                  </b-field>
                </b-field>
              </b-tab-item>
            </b-tabs>
          </b-field>
          <b-field horizontal>
            <div class="buttons">
              <button class="button is-danger" :disabled="!canCall" @click="onCall">调用</button>
              <button class="button is-info"  @click="onHistoryClick">调用历史</button>
            </div>
          </b-field>
          <b-field v-if="response" horizontal label="调用回复">
            <b-input type="textarea" v-model.trim="response" rows="10" expanded :disabled=true></b-input>
          </b-field>
        </section>
      </b-field>
    </section>
  </div>
</template>

<script>
import { checkTJValue } from '@/core/util'
import cache from '@/core/cache'
import hljs from 'highlight.js'

import JsonEditorSchema from '@/help/jsoneditorschema.md'

export default {
  name: 'maptpleditor',
  components: { JsonEditorSchema },
  data () {
    return {
      // 模板内容
      pid: null,
      mid: null,
      mname: null,
      adminuid: null,
      shareto: null, // 对谁分享使用版
      whocanedit: null, // 对谁分享专业版与编辑权限
      method: 'GET',
      url: null,
      note: null, // api说明
      status: 1,
      response: null, // 消息返回

      // 界面控制
      behavior: null, // ta:新建模板  ts:选择模板
      showEdit: false, // 显示模板编辑界面 开关
      labelPosition: 'on-border',
      users: [], // 所有用户
      selectAll: true, // 选择全部分享
      selectShareTo: [], // 选择对哪些用户分享使用版
      editorpool: [], // 待选分享专业版用户池
      selectWhoCanEdit: [], // 选择对哪些用户分享专业版
      mjstType: 0, // 使用mjst类型
      canEditFlag: false, // 根据模板分享权限设置用户是否能编辑
      editProfessional: false, // 编辑专业版模板 开关
      editTab: 0, // 编辑页签

      useProfessional: false, // 使用专业版模板 开关
      useTab: 0,

      queryOriEditor: null, // 普通版query编辑器
      bodyOriEditor: null, // 普通版body编辑器

      queryOriOpEditor: null, // 普通版query操作器
      bodyOriOpEditor: null, // 普通版body操作器

      showQueryOp: false,
      showBodyOp: false,

      // api消息内容
      jsonDataHeader: { // 公用消息头模板 JsonEditor监听的data必须是字典
      },
      jsonDataBody: { // 专业版模板 JsonEditor监听的data必须是字典
      },
      jsonDataQuery: { // 专业版模板 JsonEditor监听的data必须是字典
      },
      schemaJsonQueryOri: '{}', // 普通版query schema
      schemaJsonBodyOri: '{}', // 普通版body schema
      jsonDataQueryRequests: { // 发送消息 query JsonEditor监听的data必须是字典
      },
      jsonDataBodyRequests: { // 发送消息 body JsonEditor监听的data必须是字典
      },

      isSchemaExpOpen: false,

      isSendClick: false
    }
  },
  computed: {
    canSave () {
      const s = Boolean(this.mname) && this.url
      if (this.behavior === 'ta') {
        return Boolean(s && this.pid)
      } else if (this.behavior === 'ts') {
        return Boolean(s && this.mid)
      }
      return false
    },
    canCall () {
      return Boolean(this.mid)
    },
    isMineTpl () {
      // console.log('is mine:%s', this.adminuid)
      if (this.behavior === 'ts') {
        return cache.isMe(this.adminuid)
      }
      return false
    },
    isSuperAdmin () {
      if (cache.getUserType() === 51) {
        return true
      }
      return false
    }
  },
  methods: {
    clear (clearAll) {
      this.selectAll = false
      this.pid = null
      this.mid = null
      this.mname = null
      this.adminuid = null
      this.shareto = null
      this.whocanedit = null
      this.method = 'GET'
      this.url = null
      this.status = 1
      this.showEdit = false
      this.note = null
      this.response = null
      this.selectShareTo = []
      this.selectWhoCanEdit = []
      this.users = []
      this.editorpool = []
      this.mjstType = 0
      this.jsonDataHeader = {}
      this.jsonDataQuery = {}
      this.jsonDataBody = {}
      this.jsonDataBodyRequests = {}
      this.jsonDataQueryRequests = {}
      this.schemaJsonQueryOri = '{}'
      this.schemaJsonBodyOri = '{}'
      this.editTab = 0
      this.useTab = 0
      if (this.queryOriEditor !== null) {
        this.queryOriEditor.destroy()
        this.queryOriEditor = null
      }
      if (this.bodyOriEditor !== null) {
        this.bodyOriEditor.destroy()
        this.bodyOriEditor = null
      }
      if (this.queryOriOpEditor !== null) {
        this.queryOriOpEditor.destroy()
        this.queryOriOpEditor = null
      }
      if (this.bodyOriOpEditor !== null) {
        this.bodyOriOpEditor.destroy()
        this.bodyOriOpEditor = null
      }
      this.editProfessional = false
      this.useProfessional = false
      this.canEditFlag = false
      this.showQueryOp = false
      this.showBodyOp = false
      this.isSendClick = false

      if (clearAll) {
        this.behavior = null
      }
    },
    setCanEdit () {
      if (this.behavior === 'ts') {
        for (var i = 0; i < this.selectWhoCanEdit.length; i++) {
          // eslint-disable-next-line eqeqeq
          if (cache.isMe(Number(this.selectWhoCanEdit[i]))) {
            this.canEditFlag = true
            return
          }
        }
      }
    },
    getUsers () {
      this.mjp.get2({
        url: '/admin/get/adminlist/',
        mjpType: 'admin'
      }).then(data => {
        // console.log('data.users:%o', data.users)
        this.users = data.users
        this.setWhoCanEdit()
      }).catch(err => {
        console.error(err)
      })
    },
    setTpl (behavior, tpl) {
      this.behavior = behavior
      if (tpl.mid) {
        this.pid = tpl.pid
        this.mid = tpl.mid
        this.mname = tpl.mname
        this.adminuid = tpl.adminuid
        if (tpl.shareto) {
          this.selectShareTo = tpl.shareto.split('|')
        }
        if (tpl.whocanedit) {
          this.selectWhoCanEdit = tpl.whocanedit.split('|')
        }
        this.setCanEdit()
        if (tpl.mjsttype) {
          this.mjstType = tpl.mjsttype
        }
        this.url = tpl.url
        this.method = tpl.method
        this.status = tpl.status
        this.note = tpl.note
        if (checkTJValue({ value: tpl.tplheader, valueType: 'toml' })) {
          this.jsonDataHeader = {}
        } else if (tpl.tplheader != null && tpl.tplheader !== '') {
          this.jsonDataHeader = JSON.parse(tpl.tplheader)
        }
        if (checkTJValue({ value: tpl.tplquery, valueType: 'toml' })) {
          this.jsonDataQuery = {}
          this.jsonDataQueryRequests = {}
        } else if (tpl.tplquery != null && tpl.tplquery !== '') {
          this.jsonDataQuery = JSON.parse(tpl.tplquery)
          this.jsonDataQueryRequests = JSON.parse(tpl.tplquery)
        }
        if (checkTJValue({ value: tpl.tplbody, valueType: 'toml' })) {
          this.jsonDataBody = {}
          this.jsonDataBodyRequests = {}
        } else if (tpl.tplbody != null && tpl.tplbody !== '') {
          this.jsonDataBody = JSON.parse(tpl.tplbody)
          this.jsonDataBodyRequests = JSON.parse(tpl.tplbody)
        }
        if (tpl.tplqueryorginal != null && tpl.tplqueryorginal !== '') {
          if (tpl.tplqueryorginal !== '{}') {
            this.showQueryOp = true
          }
          this.schemaJsonQueryOri = tpl.tplqueryorginal
        }
        if (tpl.tplbodyorginal != null && tpl.tplbodyorginal !== '') {
          if (tpl.tplbodyorginal !== '{}') {
            this.showBodyOp = true
          }
          this.schemaJsonBodyOri = tpl.tplbodyorginal
        }

        if (this.showQueryOp) {
          this.useTab = 0
        } else {
          this.useTab = 1
        }

        this.onUpdateQuerySchema()
        this.onUpdateBodySchema()

        this.onUpdateQueryOp()
        this.onUpdateBodyOp()
      } else {
        this.pid = tpl.pid
        this.jsonDataHeader = {}
        this.jsonDataQuery = {}
        this.jsonDataBody = {}
      }
      this.getUsers()
      console.log('$$$$$$$$$$$$$$$')
    },
    async onSave () {
      try {
        const data = {
          mname: this.mname,
          url: this.url,
          method: this.method,
          tplheader: JSON.stringify(this.jsonDataHeader),
          tplquery: JSON.stringify(this.jsonDataQuery),
          tplbody: JSON.stringify(this.jsonDataBody),
          tplqueryorginal: this.schemaJsonQueryOri,
          tplbodyorginal: this.schemaJsonBodyOri,
          note: this.note,
          shareto: '|' + this.selectShareTo.join('|') + '|',
          whocanedit: '|' + this.selectWhoCanEdit.join('|') + '|',
          mjsttype: this.mjstType
        }
        let url = '/mapi/tpl/create/'
        if (this.behavior === 'ta') {
          data.pid = this.pid
        } else if (this.behavior === 'ts') {
          data.mid = this.mid
          data.status = this.status
          url = '/mapi/tpl/edit/'
        }
        await this.mjp.post2({
          url,
          data,
          mjpType: 'admin'
        })
        // 清除所有本地变量
        this.clear(true)
        this.$emit('tpl-saved', { pid: this.pid })
      } catch (err) {
        console.error(err)
      }
    },
    async onCall () {
      // console.log('call tpl')
      if (this.isSendClick) {
        this.hub.alert('请勿频繁点击')
        return
      }
      this.isSendClick = true
      setTimeout(() => {
        this.isSendClick = false
      }, 1000)
      if (!this.canCall) {
        this.hub.alert('参数错误，请检查。')
        return
      }
      try {
        const data = {
          mid: this.mid
        }
        if (this.useProfessional) {
          data.querynote = JSON.stringify(this.jsonDataQueryRequests)
          data.bodynote = JSON.stringify(this.jsonDataBodyRequests)
        } else {
          data.querynote = JSON.stringify(this.queryOriOpEditor.getValue())
          data.bodynote = JSON.stringify(this.bodyOriOpEditor.getValue())
        }
        const rdata = await this.mjp.post2({
          url: '/mapi/call/',
          data,
          mjpType: 'admin'
        })
        this.response = rdata.ret
        // 不知为何不更新了，强制更新一下
        // this.$forceUpdate()
      } catch (err) {
        console.error(err)
      }
    },
    onHistoryClick () {
      this.$emit('tpl-history', this.mid, 'th')
    },
    onSelectAll (select) {
      this.selectShareTo = []
      if (select) {
        this.users.forEach(item => {
          this.selectShareTo.push(item.adminuid)
        })
      }
    },
    setWhoCanEdit () {
      this.editorpool = []
      for (var j = 0; j < this.selectShareTo.length; j++) {
        for (var i = 0; i < this.users.length; i++) {
          // eslint-disable-next-line eqeqeq
          if (this.users[i].adminuid == this.selectShareTo[j]) {
            this.editorpool.push(this.users[i])
          }
        }
      }
    },
    onUpdateQuerySchema () {
      if (this.queryOriEditor !== null) {
        this.queryOriEditor.destroy()
      }
      var queryOriElement = document.getElementById('query_ori_holder')
      const config = {
        schema: JSON.parse(this.schemaJsonQueryOri),
        theme: 'html',
        iconlib: 'jqueryui'
      }
      this.queryOriEditor = new window.JSONEditor(queryOriElement, config)
      this.queryOriEditor.disable()
      // json 显示区更新
      const cJsonQueryOri = this.formatJson(JSON.stringify(this.queryOriEditor.getValue()))
      this.drawResCode(6, cJsonQueryOri)
    },
    onUpdateBodySchema () {
      if (this.bodyOriEditor !== null) {
        this.bodyOriEditor.destroy()
      }
      var bodyOriElement = document.getElementById('body_ori_holder')
      const config = {
        schema: JSON.parse(this.schemaJsonBodyOri),
        theme: 'html',
        iconlib: 'jqueryui'
      }
      this.bodyOriEditor = new window.JSONEditor(bodyOriElement, config)
      this.bodyOriEditor.disable()
      // json 显示区更新
      const cJsonBodyOri = this.formatJson(JSON.stringify(this.bodyOriEditor.getValue()))
      this.drawResCode(7, cJsonBodyOri)
    },
    onUpdateQueryOp () {
      if (this.queryOriOpEditor !== null) {
        this.queryOriOpEditor.destroy()
      }
      var queryOriOpElement = document.getElementById('query_ori_op')
      const config = {
        schema: JSON.parse(this.schemaJsonQueryOri),
        theme: 'html',
        iconlib: 'jqueryui'
      }
      this.queryOriOpEditor = new window.JSONEditor(queryOriOpElement, config)

      // json 显示区更新
      const cJsonQueryOriOp = this.formatJson(JSON.stringify(this.queryOriOpEditor.getValue()))
      this.drawResCode(8, cJsonQueryOriOp)
    },
    onUpdateBodyOp () {
      if (this.bodyOriOpEditor !== null) {
        this.bodyOriOpEditor.destroy()
      }
      var bodyOriOpElement = document.getElementById('body_ori_op')
      const config = {
        schema: JSON.parse(this.schemaJsonBodyOri),
        theme: 'html',
        iconlib: 'jqueryui'
      }
      this.bodyOriOpEditor = new window.JSONEditor(bodyOriOpElement, config)

      // json 显示区更新
      const cJsonBodyOriOp = this.formatJson(JSON.stringify(this.bodyOriOpEditor.getValue()))
      this.drawResCode(9, cJsonBodyOriOp)
    },
    onUpdateQueryJson () {
      // json 显示区更新
      const cJsonQueryOriOp = this.formatJson(JSON.stringify(this.queryOriOpEditor.getValue()))
      this.drawResCode(8, cJsonQueryOriOp)
    },
    onUpdateBodyJson () {
      // json 显示区更新
      const cJsonBodyOriOp = this.formatJson(JSON.stringify(this.bodyOriOpEditor.getValue()))
      this.drawResCode(9, cJsonBodyOriOp)
    },
    showExample () {
      this.$buefy.dialog.alert(
        {
          message: '1111111'
        }
      )
    },
    // JSON format print
    formatJson: function (txt, compress /* 是否为压缩模式 */) {
      /* 格式化JSON源码(对象转换为JSON文本) */
      var indentChar = '  '
      if (/^\s*$/.test(txt)) {
        console.error('数据为空,无法格式化! ')
        return
      }
      try {
        // eslint-disable-next-line no-eval
        var data = eval('(' + txt + ')')
      } catch (e) {
        // eslint-disable-next-line no-throw-literal
        throw ('数据源语法错误,格式化失败! 错误信息: ' + e.description, 'err')
        // eslint-disable-next-line no-unreachable
        return
      }
      var draw = []
      var line = compress ? '' : '\n'
      var nodeCount = 0
      var maxDepth = 0
      var notify = function (name, value, isLast, indent /* 缩进 */, formObj, nodeCount, maxDepth) {
        nodeCount++ /* 节点计数 */
        for (var i = 0, tab = ''; i < indent; i++) tab += indentChar /* 缩进HTML */
        tab = compress ? '  ' : tab /* 压缩模式忽略缩进 */
        maxDepth = ++indent /* 缩进递增并记录 */
        if (value && value.constructor === Array) {
          /* 处理数组 */
          draw.push(
            tab + (formObj ? '"' + name + '":' : '') + '[' + line
          ) /* 缩进'[' 然后换行 */
          // eslint-disable-next-line no-redeclare
          for (var i = 0; i < value.length; i++) notify(i, value[i], i === value.length - 1, indent, false, nodeCount)
          draw.push(
            tab + ']' + (isLast ? line : ',' + line)
          ) /* 缩进']'换行,若非尾元素则添加逗号 */
        } else if (value && typeof value === 'object') {
          /* 处理对象 */
          draw.push(
            tab + (formObj ? '"' + name + '":' : '') + '{' + line
          ) /* 缩进'{' 然后换行 */
          var len = 0
          // eslint-disable-next-line no-redeclare
          var i = 0
          for (var key in value) len++
          // eslint-disable-next-line no-redeclare
          for (var key in value) notify(key, value[key], ++i === len, indent, true, nodeCount)
          draw.push(
            tab + '}' + (isLast ? line : ',' + line)
          ) /* 缩进'}'换行,若非尾元素则添加逗号 */
        } else {
          if (typeof value === 'string') value = '"' + value + '"'
          draw.push(
            tab +
            (formObj ? '"' + name + '":' : '') +
            value +
            (isLast ? '' : ',') +
            line
          )
        }
      }
      var isLast = true
      var indent = 0
      notify('', data, isLast, indent, false, nodeCount, maxDepth)
      return draw.join('')
    },
    // 绘制res body
    drawResCode: function (index, content) {
      if (content != null) {
        if (index === 1) {
          const target = document.getElementById('header_code')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 2) {
          const target = document.getElementById('query_code')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 3) {
          const target = document.getElementById('body_code')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 4) {
          const target = document.getElementById('query_requests')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 5) {
          const target = document.getElementById('body_requests')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 6) {
          const target = document.getElementById('query_ori_code')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 7) {
          const target = document.getElementById('body_ori_code')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 8) {
          const target = document.getElementById('query_ori_op_code')
          target.textContent = content
          hljs.highlightBlock(target)
        } else if (index === 9) {
          const target = document.getElementById('body_ori_op_code')
          target.textContent = content
          hljs.highlightBlock(target)
        }
      }
    }
  },
  watch: {
    jsonDataHeader: function () {
      const cheader = this.formatJson(JSON.stringify(this.jsonDataHeader))
      this.drawResCode(1, cheader)
    },
    jsonDataQuery: function () {
      const cquery = this.formatJson(JSON.stringify(this.jsonDataQuery))
      this.drawResCode(2, cquery)
    },
    jsonDataBody: function () {
      const cbody = this.formatJson(JSON.stringify(this.jsonDataBody))
      this.drawResCode(3, cbody)
    },
    jsonDataQueryRequests: function () {
      const rquery = this.formatJson(JSON.stringify(this.jsonDataQueryRequests))
      this.drawResCode(4, rquery)
    },
    jsonDataBodyRequests: function () {
      const rbody = this.formatJson(JSON.stringify(this.jsonDataBodyRequests))
      this.drawResCode(5, rbody)
    },
    selectShareTo: function () {
      this.setWhoCanEdit()
    },
    editProfessional: function () {
      this.editTab = 0
    },
    useProfessional: function () {
      if (this.useProfessional) {
        this.useTab = 2
      } else {
        if (this.showQueryOp) {
          this.useTab = 0
        } else {
          this.useTab = 1
        }
      }
    }
  }
}
</script>
