<template>
<div id="mapi">
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="所有项目">
      <mapi-all-projects
        ref="allProjects"
        @add-project="onAddProject"
        @select-project="onSelectProject"/>
    </b-tab-item>
    <b-tab-item :disabled="!isProjectDeal">
      <template slot="header">
        <span>{{ projectBehaviorLabel }}</span>
      </template>
      <mapi-project-detail
        ref="projectDetail"
        @project-saved="onProjectSaved"
        @add-tpl="onAddTpl"
        @select-tpl="onSelectTpl"
        @tpl-history="onTplHistory"/>
    </b-tab-item>
    <b-tab-item :disabled="!isTplDeal">
      <template slot="header">
        <span>{{ tplBehaviorLabel }}</span>
      </template>
      <mapi-tpl-detail
        ref="tplDetail"
        @tpl-saved="onTplSaved"
        @tpl-history="onTplHistory"/>
    </b-tab-item>
    <b-tab-item label="调用历史" :disabled="!isCallHistory">
      <mapi-history ref="history" />
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>

import MapiAllProjects from '@/components/mapi/MapiAllProjects'
import MapiProjectDetail from '@/components/mapi/MapiProjectDetail'
import MapiTplDetail from '@/components/mapi/MapiTplDetail'
import MapiHistory from '@/components/mapi/MapiHistory'

export default {
  name: 'mapi',
  components: { MapiAllProjects, MapiProjectDetail, MapiTplDetail, MapiHistory },
  data () {
    return {
      tabIndex: 0,
      // pa新建项目/pe编辑项目/ta新增api/ts展示api列表/h调用历史
      behavior: null,
      // 正在编辑或新增的项目
      editPid: null,
      selectProject: null,
      selectTpl: null
    }
  },
  computed: {
    isProjectDeal () {
      return this.behavior === 'pa' || this.behavior === 'ps' || this.isTplDeal || this.behavior === 'ph'
    },
    isTplDeal () {
      return this.behavior === 'ta' || this.behavior === 'ts' || this.behavior === 'th'
    },
    isCallHistory () {
      return this.behavior === 'ph' || this.behavior === 'th'
    },
    projectBehaviorLabel () {
      console.log('behavor:%s', this.behavior)
      if (this.behavior === 'pa') {
        return '新增项目'
      }
      return '已选项目'
    },
    tplBehaviorLabel () {
      if (this.behavior === 'ta') {
        return '新增API'
      }
      return '已选API'
    }
  },
  methods: {
    clearProject () {
      this.editPid = null
      this.selectProject = null
      this.$refs.projectDetail.clear(true)
    },
    clearTpl () {
      this.selectTpl = null
      this.$refs.tplDetail.clear(true)
    },
    onAddProject () {
      this.behavior = 'pa'
      this.tabIndex = 1
      this.$refs.projectDetail.setProject(this.behavior, null)
    },
    onSelectProject (project, allProjects) {
      this.behavior = 'ps'
      this.$refs.projectDetail.setProject(this.behavior, project)
      this.$refs.projectDetail.setAllProject(allProjects)
      this.tabIndex = 1
      this.editPid = project.pid
      this.selectProject = project
    },
    onProjectSaved (project) {
      this.tabIndex = 0
      this.$refs.allProjects.getRecords()
    },
    onAddTpl (project) {
      this.behavior = 'ta'
      this.editPid = project.pid
      this.tabIndex = 2
      this.$refs.tplDetail.clear(true)
      this.$refs.tplDetail.setTpl(this.behavior, { pid: this.editPid })
    },
    onSelectTpl (tpl) {
      console.log('onSelectTpl')
      this.behavior = 'ts'
      this.tabIndex = 2
      this.selectTpl = tpl
      this.$refs.tplDetail.clear(true)
      this.$refs.tplDetail.setTpl(this.behavior, tpl)
    },
    onTplSaved (project) {
      this.behavior = 'ps'
      this.$refs.projectDetail.setProject(this.behavior, this.selectProject)
      this.tabIndex = 1
      this.editPid = project.pid
    },
    onTplHistory (mid, fromto) {
      this.tabIndex = 3
      this.behavior = fromto
      this.$refs.history.clear()
      this.$refs.history.setQuery({ mid: mid })
      this.$refs.history.getRecords()
    }
  },
  watch: {
    tabIndex (to, from) {
      if (to === 0) {
        this.clearProject()
        this.clearTpl()
        this.behavior = 0
        this.$refs.allProjects.getRecords()
      }
      if (to === 1 && (from === 2 || from === 3)) {
        this.clearTpl()
        this.behavior = 'ps'
      }
      if (to === 2 && from === 3) {
        this.behavior = 'ts'
      }
    }
  }
}
</script>
